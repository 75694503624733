import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    EDUCATION: "Education",
    // DETAILED: "Details",
    // MOCKUPS: "Mockups",
    // YOUTUBE: "Youtube Videos",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Tosa",
      type: types.DOCUMENT,
      document: {
        projectInfo:
            "Proudly delivered a comprehensive web platform to our customer TOSA, a leader in the local Sustainable Consumables Industry. This platform showcases and enables the transaction of TOSA's eco-friendly disposable products. Our design prioritised clarity, ensuring an intuitive and instantaneous user experience that appeals to all. Since 2011, TOSA has been a standard-bearer in hygiene and customer satisfaction across sectors like healthcare and food service. The platform reflects their commitment to environmental sustainability, enhancing the customer journey with a diverse, accessible product range. TOSA, with our technical support, stands as a trusted, efficient partner in the sustainable consumables market.",
        client: "Arsenie Tomuța",
        technologies: "Wordpress, Ecommerce",
        industry: "Sustainable Consumables",
        date: "October 4, 2023",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          "images/projects/tosa1.png",
          "images/projects/tosa2.png",
          "images/projects/tosa3.png",
        ],
      },

      thumbImage: "images/projects/tosa2.png",

      categories: [filters.DETAILED],
    },
    {
      title: "Med School Training",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "The project aims to develop a free online platform to assist, coordinate, and enhance the performance of candidates from all geographical and social backgrounds who are seeking admission to the UMF \"V. Babes\" University from Timisoara. <br/> The platform will provides tools and resources to help candidates prepare for admission exams, including personalized study plans and mocked tests. <br/> The platform's ultimate goal is to promote equal access to education and improve the chances of success for candidates regardless of their social or economic status.",
        client: "Dumitru Șutoi",
        technologies: "React, Material-UI, Firebase, Express",
        industry: "Healthcare",
        date: "June 4, 2022",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          "images/projects/umft1.png",
          "images/projects/umft2.png",
          "images/projects/umft3.png",
          "images/projects/umft4.png",
        ],
      },

      thumbImage: "images/projects/umft1.png",

      categories: [filters.EDUCATION],
    },
    {
      title: "ReziExpress",
      type: types.DOCUMENT,
      document: {
        projectInfo:
            "ReziExpress is a web application designed to help students prepare for the admission exam to the residency program at UMF \"V. Babes\" Faculty of Medicine in Timisoara. The platform provides access to an extensive question bank that covers all the topics that may appear on the exam.\n" +
            "\n" +
            "The platform allows students to select the medical domains they want to study and practice with the questions in those domains. Each test consists of a specific number of multiple-choice questions and is designed to assess the student's level of preparation in that subject area.",
        client: "Dumitru Șutoi",
        technologies: "React, Material-UI, Firebase, AWS",
        industry: "Education, Healthcare",
        date: "October 5, 2020",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          "images/projects/rezi1.png",
          "images/projects/rezi2.png",
          "images/projects/rezi3.png",
        ],
      },

      thumbImage: "images/projects/rezi1.png",

      categories: [filters.EDUCATION],
    },
    // {
    //   title: "Mockups Design 1",
    //   type: types.IMAGE,
    //
    //   thumbImage: "images/projects/project-2.jpg",
    //
    //   categories: [filters.MOCKUPS],
    // },
    // {
    //   title: "YouTube Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: false,
    //     id: "PMNnEEEacCg",
    //   },
    //   thumbImage: "images/projects/project-3.jpg",
    //
    //   categories: [filters.YOUTUBE],
    // },
    // {
    //   title: "Vimeo Video",
    //   type: types.VIDEO,
    //   video: {
    //     vimeo: true,
    //     id: "259411563",
    //   },
    //
    //   thumbImage: "images/projects/project-4.jpg",
    //   categories: [filters.VIMEO],
    // },
    // {
    //   title: "Detailed Project 2",
    //   type: types.DOCUMENT,
    //   document: {
    //     projectInfo:
    //       "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //     client: "Ruby Clinton",
    //     technologies: "iOS, HTML5, CSS3, PHP, Java",
    //     industry: "Art & Design",
    //     date: "July 16, 2019",
    //     url: {
    //       name: "www.example.com",
    //       link: "https://www.example.com",
    //     },
    //     sliderImages: [
    //       "images/projects/project-1.jpg",
    //       "images/projects/project-2.jpg",
    //     ],
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   categories: [filters.DETAILED],
    // },
    // {
    //   title: "Mockups Design 2",
    //   type: types.IMAGE,
    //
    //   thumbImage: "images/projects/project-6.jpg",
    //
    //   categories: [filters.MOCKUPS],
    // },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            {/*<span className="text-light">Category</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
