import React from "react";
import resumeFile from "../documents/resume_alexandru_ianas.pdf";


const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2016 - 2018",
      title: "Master's degree in Artificial intelligence",
      place: "West University of Timisoara",
      desc: "I gained practical experience working with tools and technologies commonly used in the AI industry, such as TensorFlow, Python, and deep learning frameworks.",
    },
    {
      yearRange: "2013 - 2016",
      title: "Bachelor's Degree in Informatics",
      place: "West University of Timisoara",
      desc: "I gained a deep understanding of programming languages, data structures, algorithms, and software development methodologies.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2019 - current",
      title: "Full Stack Developer",
      place: "Victory Square Partners",
      desc: "I'm currently developing software for a smart building company that offers cloud-based access control and video surveillance products.",
    },
    {
      yearRange: "2018 - 2019",
      title: "Full Stack Developer",
      place: "3Pillar Global",
      desc: "My role included working closely with clients to understand their requirements, and writing clean, scalable code that meets project goals within the designated timeline and budget.",
    },
    {
      yearRange: "2017 - 2018",
      title: "Software development Engineer",
      place: "Nokia",
      desc: " Nokia gave me the opportunity to design, develop, and test software for 5G network components and features. I also collaborated with other team members to ensure the successful delivery of projects.",
    },
    {
      yearRange: "2015 - 2017",
      title: "Web Developer",
      place: "West University of Timisoara",
      desc: "I started out designing and implementing website layouts, creating and updating content, and ensuring that websites are user-friendly, accessible, and responsive",
    },
  ];

  const skills = [
    {
      name: "NodeJS",
      percent: 90,
    },
    {
      name: "Wordpress",
      percent: 95,
    },
    {
      name: "JavaScript",
      percent: 90,
    },
    {
      name: "React JS",
      percent: 90,
    },
    {
      name: "Angular",
      percent: 70,
    },
    {
      name: "Material UI",
      percent: 99,
    },
    {
      name: "AWS",
      percent: 75,
    },
    {
      name: "Firebase",
      percent: 90,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
