import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "How Can I Help You?",
      answer:
          "Whether you are looking to build a brand new web application from scratch or need help maintaining and updating an existing site, I have the skills and expertise needed to deliver top-quality results. I am committed to delivering work that is reliable, scalable, and easy to maintain, and I am always striving to improve my skills and stay up-to-date with the latest trends and best practices in the web development industry.",
    },
    {
      question: "What's my guiding process?",
      answer:
          "I believe in keeping the workflow process simple and efficient for my clients. From initial consultation to project completion, I will work closely with you to ensure that the process is streamlined and effective. This includes clear communication, setting realistic timelines and milestones, and regular updates to keep you informed every step of the way. Let's work together to make your project a success.",
    },
    {
      question: "Can I lead your team?",
      answer:
          "As a skilled and experienced freelancer, I am well-equipped to lead your team and help guide your project to success. With my strong communication skills and ability to collaborate effectively with team members, I am confident that I can provide the leadership and direction needed to bring your project to fruition.",
    },
    {
      question: "Preferred language and timezone?",
      answer:"I am fluent in both English and Romanian. I'm also comfortable communicating with clients from all around the world. My preferred timezone is UTC+02:00, but I am flexible and able to adjust my work schedule to accommodate for calls from different time zones."
    },
    {
      question: "My hourly rate?",
      answer:
          "My hourly rate is negotiable and can vary depending on the scope and complexity of the project. Contact me to discuss your project and we can work together to determine a fair and reasonable rate that fits within your budget.",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
